import { For, Show, Suspense } from "solid-js";
import { formatDateUStoFrenchShortString } from "~/utils/format";
import CommonFirstScreen from "~/components/shared/CommonFirstScreen";
import {
  AutopromosRenderer,
  hasMultipleShortcodes,
} from "~/components/shared/Autopromos/AutopromosRenderer";
import Breadcrumb from "~/components/shared/Breadcrumb";
import CommonIntroduction from "~/components/shared/CommonIntroduction";
import { urlRs } from "~/utils/url";
import SectionParagraphComponent from "~/components/shared/SectionParagraphComponent";
import RelatedContentComponent from "~/components/shared/RelatedContentComponent";
import ProgramsNearbyList from "../Program/Components/ProgramsNearbyList";

import type { Article, SectionParagraph } from "~/types/drupal_jsonapi";

import "~/components/shared/CommonPageStyle.css";
import GtmVars from "../shared/Trackers/GtmVars";
import { ProjectErrorBoundary } from "../shared/ProjectErrorBoundary";
import LotDrawer from "~/components/Program/Components/LotDrawer";
import { useLotActive } from "~/contexts/LotActiveContext";

export default function Article(props: { article: Article }) {
  const gtmValues = () => {
    const breadcrumbItems = props.article.breadcrumb.slice(1).toReversed();
    return {
      "all.pageType": "other",
      "all.mainCategory": breadcrumbItems.at(0)?.text ?? "Non défini",
      "all.category": breadcrumbItems.at(1)?.text ?? "Non défini",
      "all.subCategory": breadcrumbItems.at(2)?.text ?? "Non défini",
      "all.subsubCategory": breadcrumbItems.at(3)?.text ?? "Non défini",
      "all.subsubsubCategory": breadcrumbItems.at(4)?.text ?? "Non défini",
      template: "article",
    };
  };

  const [lotProvider] = useLotActive();

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="common-page-style node-article" data-ga-zone="in-page">
        <CommonFirstScreen fieldImage={props.article.field_image} />

        <div class="outer-content-area">
          <section class="content-area">
            <div class="inner">
              <CommonIntroduction
                title={props.article.title}
                catchline={props.article.field_catchline}
                link={urlRs("articles", "/le-neuf-decrypte/l-actu-du-neuf/")}
                linkText="Retour à l’actu du neuf"
                publishedDate={formatDateUStoFrenchShortString(
                  props.article.created,
                )}
                readingTime={props.article.field_reading_time}
              />

              <Show when={props.article.field_sections.length > 0}>
                <div data-test="paragraphs">
                  <For each={props.article.field_sections}>
                    {(section: SectionParagraph, index) => (
                      <SectionParagraphComponent
                        section={section}
                        index={index() + 1}
                        article={props.article}
                      />
                    )}
                  </For>
                </div>
              </Show>
            </div>
          </section>
          {/* RELATED */}
          <Show when={props.article.field_contents.length > 0}>
            <RelatedContentComponent contents={props.article.field_contents} />
          </Show>
        </div>
      </article>

      {/* PROGRAMS */}
      <Show when={props.article.programs.length > 0}>
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer
              wrapper={lotProvider.wrapper!}
              lot={lotProvider.lot!}
              isNotFromProgram
            />
          </Suspense>
        </Show>
        <Show when={props.article.programs.length}>
          <section class="nearby-common-pages" data-test="programs-article">
            <h2>Découvrez nos programmes</h2>
            <ProjectErrorBoundary>
              <ProgramsNearbyList nearbyPrograms={props.article.programs} />
            </ProjectErrorBoundary>
          </section>
        </Show>
      </Show>

      {/* AUTOPROMOS */}
      <Show when={props.article.field_ads}>
        <section
          class="autopromos-container"
          data-ga-zone="ads"
          data-test="autopromos"
          classList={{
            single: !hasMultipleShortcodes(props.article.field_ads!),
            multiple: hasMultipleShortcodes(props.article.field_ads!),
          }}
        >
          <AutopromosRenderer
            text={props.article.field_ads!}
            fromFieldName="field_ads"
          />
        </section>
      </Show>

      <Breadcrumb items={props.article.breadcrumb.slice(1)} />
    </>
  );
}
